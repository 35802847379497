import React from "react"
import { GatsbyProps } from "Src/global-types"

import StandardLayout from "Components/core/StandardLayout"
import SEO from "Components/core/SEO"

const FirstReactProject: React.FC<GatsbyProps> = ({ location }) => {
  return (
    <StandardLayout location={location}>
      <SEO title="First React Project" />
      <h1>My First React Project</h1>
      <iframe
        height="500"
        style={{ width: "100%" }}
        scrolling="no"
        title="Steam Games Filter (React)"
        src="https://codepen.io/liammyles/embed/preview/MPYXJq?height=265&theme-id=dark&default-tab=result"
        frameBorder="no"
      >
        See the Pen
        <a href="https://codepen.io/liammyles/pen/MPYXJq">
          Steam Games Filter (React)
        </a>{" "}
        by Liam Myles (<a href="https://codepen.io/liammyles">@liammyles</a>) on{" "}
        <a href="https://codepen.io">CodePen</a>.
      </iframe>
    </StandardLayout>
  )
}

export default FirstReactProject
